import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/seriesContent/:series_id',
    name: 'SeriesContent',
    component: () => import(/* webpackChunkName: "seriesContent" */ '../views/page/SeriesContent.vue'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/phrases',
    name: 'Phrases',
    component: () => import(/* webpackChunkName: "phrases" */ '../views/page/Phrases.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "Courses" */ '../views/page/test.vue')
  },
  {
    path: '/vocabulary',
    name: 'Vocabulary ',
    component: () => import(/* webpackChunkName: "vocabulary " */ '../views/page/Vocabulary.vue'),
    meta: {
      IsAuth: true
    }
  },{
    path: '/Speaking/:cat_id',
    name: 'speaking',
    component: () => import(/* webpackChunkName: "Speaking" */ '../views/page/Speaking.vue'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  
  {
    path: '/teachersContent/:teachers_id',
    name: 'TeachersContent',
    component: () => import(/* webpackChunkName: "TeachersContent" */ '../views/page/TeachersContent.vue'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/user/Login.vue')
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: () => import(/* webpackChunkName: "subscribe" */ '../views/user/Subscribe.vue')
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/user/Unsubscribe.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/contents/:content_id',
    name: 'Contents',
    component: () => import(/* webpackChunkName: "contents" */ '../views/page/Contents.vue'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/searchs/:search_id',
    name: 'Searchs',
    component: () => import(/* webpackChunkName: "searchs" */ '../views/page/Searchs.vue'),
    props: true
  },
  {
    path: '/:pathMatch(.*)*', 
    name: 'NotFounds',
    component: () => import(/* webpackChunkName: "notFounds" */ '../views/page/NotFounds.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach((to, from, next ) => {
  const { $cookie } = router.app.config.globalProperties
   if(to.meta.IsAuth) {
    if($cookie.isCookieAvailable("msisdn") && $cookie.isCookieAvailable("status")) {
      next();
    } else {
      // next('/Subscribe')
      next(window.open("https://dsplp.sd.zain.com/?p=2847968194", "_self"));
    }
   } else {
     next();
   }
})

export default router